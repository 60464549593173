import { AspectRatio, Box, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import { Controller, EffectCoverflow, Navigation, Swiper as _Swiper } from "swiper"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/navigation"
import "swiper/css/scrollbar"

import format from "date-fns/format"
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import { Header, Stat } from "../components"
import { AllAlerts, AllImages } from "../components/sites"

const DashboardPage: React.FC = () => {
	const { site = "0" } = useParams<{ site: string }>()

	const navigate = useNavigate()
	const { pathname } = useLocation()

	const [controlledSwiper, setControlledSwiper] = useState<_Swiper>()

	useEffect(() => {
		if (Number(site) !== controlledSwiper?.activeIndex && controlledSwiper?.controller) {
			controlledSwiper.slideTo(Number(site))
		}
	}, [site, controlledSwiper])

	return (
		<VStack w="full" align="stretch" p="8" spacing={4}>
			<Header
				title={
					[
						{
							name: "Mendota",
							date: "2023-04-19T08:35:09Z",
						},
						{
							name: "Manley",
							date: "2023-04-19T19:51:26Z",
						},
						{
							name: "Bartelt",
							date: "2023-04-19T20:15:47Z",
						},
					][Number(site)].name
				}
			/>
			<VStack w="full" align="stretch" spacing={16}>
				<AspectRatio alignSelf="center" w="full" maxW="4xl" ratio={24 / 9}>
					<Swiper
						controller={{ control: controlledSwiper }}
						onSwiper={setControlledSwiper}
						modules={[Navigation, Controller, EffectCoverflow]}
						effect="coverflow"
						coverflowEffect={{ rotate: 50, stretch: 0, depth: 100, modifier: 1, slideShadows: true }}
						navigation
						spaceBetween={10}
						slidesPerView={1.5}
						centeredSlides
						onSlideChange={(swiper) => {
							if (pathname !== `/sites/${swiper.activeIndex}/${pathname.split("/")[pathname.split("/").length - 1]}`) navigate(`/sites/${swiper.activeIndex}/${pathname.split("/")[pathname.split("/").length - 1]}`)
						}}
						defaultValue={1}
						style={{ widows: "100%" }}
					>
						{[
							{
								name: "Mendota",
								date: "2023-04-19T08:35:09Z",
							},
							{
								name: "Manley",
								date: "2023-04-19T19:51:26Z",
							},
							{
								name: "Bartelt",
								date: "2023-04-19T20:15:47Z",
							},
						].map(({ date }, index) => (
							<SwiperSlide key={index}>
								<Box w="full" maxW="4xl" pos="relative">
									<AspectRatio w="full" ratio={16 / 9}>
										<Image src={`/image-${index + 1}.jpg`} />
									</AspectRatio>
									<VStack pos="absolute" bottom="0" left="0" right="0" bgColor="blackAlpha.500" p="2">
										<Stat label={<Text color="whiteAlpha.800">Latest image taken</Text>} value={<Text color="whiteAlpha.800">{format(new Date(date), "MMM dd, yyyy p")}</Text>} />
									</VStack>
								</Box>
							</SwiperSlide>
						))}
					</Swiper>
				</AspectRatio>
				<Tabs variant="soft-rounded" colorScheme="primary" size="sm" defaultIndex={pathname.split("/")[pathname.split("/").length - 1] === "images" ? 0 : 1}>
					<TabList>
						<Tab as={NavLink} to={`/sites/${Number(site)}/images`}>
							Images
						</Tab>
						<Tab as={NavLink} to={`/sites/${Number(site)}/alerts`}>
							Alerts
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel px="0">
							<AllImages site={Number(site)} />
						</TabPanel>
						<TabPanel px="0">
							<AllAlerts site={Number(site)} image={Number(site) === 0 ? 4 : Number(site) === 1 ? 5 : 2} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</VStack>
		</VStack>
	)
}

export default DashboardPage
