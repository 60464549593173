import { VStack, Flex, LinkBox, LinkOverlay, AspectRatio, Heading, Image } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { Label } from "../common"

const dates = ["2023-04-09T08:35:09Z", "2023-03-31T19:51:26Z", "2023-03-21T20:15:47Z", "2023-03-11T08:23:12Z", "2023-03-01T08:35:09Z", "2023-02-22T19:51:26Z", "2023-02-12T20:15:47Z", "2023-02-02T08:23:12Z"]

export type AllImagesProps = { site: number }

export const AllImages: React.FC<AllImagesProps> = ({ site }) => {
	return (
		<VStack w="full" align="stretch">
			<Flex w="full" flexWrap="wrap">
				{dates.map((date, index) => (
					<LinkBox
						key={date}
						w="full"
						maxW="2xs"
						pos="relative"
						m="1"
						border="6px solid"
						borderColor={(site === 0 && index === 4) || (site === 1 && index === 5) || (site === 2 && index === 2) ? "error.500" : "success.500"}
						rounded="xl"
						cursor="pointer"
					>
						<LinkOverlay as={NavLink} to={`/sites/${site}/images/${index}`}>
							<AspectRatio w="full" ratio={16 / 9}>
								<Image src={`/image-${site + 1}${(site === 0 && index === 4) || (site === 1 && index === 5) ? "-changes" : ""}.jpg`} rounded="md" />
							</AspectRatio>
						</LinkOverlay>
						<VStack pos="absolute" bottom="0" left="0" right="0" bgColor="blackAlpha.500" p="2" roundedBottom="md">
							<Label
								name={
									<Heading fontSize="md" color="grayscale.off-white">
										{format(new Date(date), "MMM dd, yyyy p")}
									</Heading>
								}
							/>
						</VStack>
					</LinkBox>
				))}
			</Flex>
		</VStack>
	)
}
