import { AspectRatio, HStack, Heading, Image, LinkBox, LinkOverlay, List, ListItem, Text, VStack, chakra } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"

const changes = [
	["minor construction work", "new road"],
	["new buildings", "human settlement"],
	["new trees", "new water body"],
]
const dates = ["2023-04-09T08:35:09Z", "2023-03-31T19:51:26Z", "2023-03-21T20:15:47Z", "2023-03-11T08:23:12Z", "2023-03-01T08:35:09Z", "2023-02-22T19:51:26Z", "2023-02-12T20:15:47Z", "2023-02-02T08:23:12Z"]

export type AllAlertsProps = { site: number; image: number }

export const AllAlerts: React.FC<AllAlertsProps> = ({ site, image }) => {
	return (
		<VStack w="full" align="stretch">
			<List w="full">
				<ListItem as={LinkBox} w="full" pos="relative" m="1" cursor="pointer" bgColor="gray.100" rounded="xl" p="2">
					<LinkOverlay as={NavLink} to={`/sites/${site}/images/${image}`}>
						<HStack w="full" align="flex-start" spacing={6}>
							<AspectRatio w="full" maxW="3xs" ratio={16 / 9} border="6px solid" borderColor="error.500" rounded="xl">
								<Image src={`/image-${site + 1}-changes.jpg`} rounded="md" />
							</AspectRatio>
							<VStack w="full" align="flex-start">
								<Heading fontSize="sm" color="grayscale.label">
									{format(new Date(dates[image]), "MMM dd, yyyy p")}
								</Heading>
								<Text fontSize="sm">
									Detected{" "}
									<chakra.span fontWeight="bold" color="red.600">
										{changes[site].join(", ")}
									</chakra.span>
								</Text>
							</VStack>
						</HStack>
					</LinkOverlay>
				</ListItem>
			</List>
		</VStack>
	)
}
