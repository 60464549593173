import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { Scaffold } from "../components"
import ChangesPage from "../pages/Changes.page"
import DashboardPage from "../pages/Dashboard.page"

export const RootRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<Scaffold />}>
				<Route path="/sites/:site/images" element={<DashboardPage />} />
				<Route path="/sites/:site/alerts" element={<DashboardPage />} />
				<Route path="/sites/:site/images/:image" element={<ChangesPage />} />
				<Route path="/" element={<Navigate to="/sites/0/images" replace />} />
				<Route path="*" element={<Navigate to="/sites/0/images" replace />} />
			</Route>
		</Routes>
	)
}
