import { Accordion, VStack } from "@chakra-ui/react"
import React from "react"
import { PieChart } from "react-feather"
import { NavLink } from "react-router-dom"
import { Logo } from "../common"
import { AccountPopover } from "../common/AccountPopover"
import { SidebarButton } from "./SidebarButton"

export const Sidebar: React.FC = () => {
	return (
		<VStack pos="absolute" top="0" left="0" w="320px" h="100vh" bgColor="white !important" align="stretch" justify="space-between" overflow="hidden" zIndex={10} p="4" backgroundColor="grayscale.background">
			<VStack h="full" align="stretch" overflow="auto" pb="4" spacing={8}>
				<NavLink to="/">
					<Logo />
				</NavLink>

				<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
					<SidebarButton to="/sites" icon={PieChart} isActive>
						Sites
					</SidebarButton>
				</Accordion>
			</VStack>
			<AccountPopover />
		</VStack>
	)
}
