import { Avatar, HStack, Heading, VStack } from "@chakra-ui/react"
import React from "react"

export type UserTagProps = {
	size?: "md" | "sm"
}

export const UserTag: React.FC<UserTagProps> = ({ size = "md" }) => {
	return (
		<HStack>
			<Avatar src="https://api.dicebear.com/6.x/adventurer/svg?seed=Cuddles" cursor="pointer" name="Avinash Sinha" rounded="xl" size={size} />
			<VStack align="flex-start" spacing={0}>
				<Heading fontSize={size} color="grayscale.title">
					Avinash Sinha
				</Heading>
				<Heading fontSize={size === "md" ? "sm" : "xs"} color="grayscale.label">
					@avi
				</Heading>
			</VStack>
		</HStack>
	)
}
