import { AspectRatio, Box, VStack } from "@chakra-ui/react"
import React from "react"

export const Logo: React.FC = () => {
	return (
		<VStack w="full" spacing={0}>
			<AspectRatio w="full" maxW="24" ratio={20 / 5}>
				<Box w="full" h="full" backgroundImage="/logo-192.png" backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="contain" />
			</AspectRatio>
		</VStack>
	)
}
