/* eslint-disable @typescript-eslint/indent */
import { Button, ButtonProps, Icon as CUIIcon, HStack, Text } from "@chakra-ui/react"
import React from "react"
import { ChevronDown, Icon } from "react-feather"
import { NavLink, useLocation } from "react-router-dom"

export type SidebarButtonProps = {
	icon?: Icon
	children: string
	isNested?: boolean
	to?: string
	isActive?: boolean
} & ButtonProps

export const SidebarButton: React.FC<SidebarButtonProps> = ({ icon, children, to, isNested, isActive: isActiveFromProps, ...props }) => {
	const isActive = new RegExp(`^${to}(/|)$`).test(useLocation().pathname)

	return !isNested && to ? (
		<Button as={NavLink} to={to} w="full" bgColor={isActive || isActiveFromProps ? "blackAlpha.100" : "transparent"} _hover={{ bgColor: "white" }} colorScheme="whiteAlpha" {...props}>
			<HStack w="full" spacing={4}>
				{icon && <CUIIcon as={icon} fontSize={isActive || isActiveFromProps ? "lg" : "md"} color="grayscale.title" />}
				<Text fontWeight={isActive || isActiveFromProps ? "black" : "normal"} textAlign="left" fontSize="sm" transition="font-weight .2s ease-in-out">
					{children}
				</Text>
			</HStack>
		</Button>
	) : (
		<Button w="full" bgColor={isActive || isActiveFromProps ? "blackAlpha.100" : "transparent"} _hover={{ bgColor: "white" }} colorScheme="whiteAlpha" {...props}>
			<HStack w="full" justify="space-between">
				<HStack w="full" spacing={4}>
					{icon && <CUIIcon as={icon} fontSize={isActive || isActiveFromProps ? "lg" : "md"} color="grayscale.title" />}
					<Text fontWeight={isActive || isActiveFromProps ? "black" : "normal"} textAlign="left" fontSize="sm" transition="font-weight .2s ease-in-out">
						{children}
					</Text>
				</HStack>
				<CUIIcon as={ChevronDown} color="grayscale.title" />
			</HStack>
		</Button>
	)
}
