import { AspectRatio, HStack, Heading, Image, VStack } from "@chakra-ui/react"
import React from "react"

// Import Swiper React components

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/navigation"
import "swiper/css/scrollbar"

import format from "date-fns/format"
import { useParams } from "react-router-dom"
import { Header } from "../components"

const changes = [
	["minor construction work", "new road"],
	["new buildings", "human settlement"],
	["new trees", "new water body"],
]
const dates = ["2023-04-09T08:35:09Z", "2023-03-31T19:51:26Z", "2023-03-21T20:15:47Z", "2023-03-11T08:23:12Z", "2023-03-01T08:35:09Z", "2023-02-22T19:51:26Z", "2023-02-12T20:15:47Z", "2023-02-02T08:23:12Z"]

const ChangesPage: React.FC = () => {
	const { site = "0", image = "0" } = useParams<{ site: string; image: string }>()

	return (
		<VStack w="full" align="flex-start" p="8" spacing={4}>
			<Header
				title={
					[
						{
							name: "Mendota",
							date: "2023-04-19T08:35:09Z",
						},
						{
							name: "Manley",
							date: "2023-04-19T19:51:26Z",
						},
						{
							name: "Bartelt",
							date: "2023-04-19T20:15:47Z",
						},
					][Number(site)].name
				}
			/>

			{(Number(site) === 0 && Number(image) === 4) || (Number(site) === 1 && Number(image) === 5) || (Number(site) === 2 && Number(image) === 2) ? (
				<VStack w="full" align="center" spacing={12}>
					<VStack w="full" pt="4">
						<Heading fontSize="sm" color="grayscale.label">
							2 changes detected between
						</Heading>

						<HStack spacing={6}>
							<Heading fontSize="md" color="success.600">
								{format(new Date(dates[Number(image) + 1]), "MMM dd, yyyy")}
							</Heading>

							<Heading fontSize="md" color="error.600">
								{format(new Date(dates[Number(image)]), "MMM dd, yyyy")}
							</Heading>
						</HStack>

						<HStack w="full" pos="relative" spacing={6}>
							<AspectRatio w="full" maxW="3xl" ratio={16 / 9}>
								<Image src={`/image-${Number(site) + 1}.jpg`} border="6px solid" borderColor="success.600" />
							</AspectRatio>

							<AspectRatio w="full" maxW="3xl" ratio={16 / 9}>
								<Image src={`/image-${Number(site) + 1}-changes.jpg`} border="6px solid" borderColor="error.600" />
							</AspectRatio>

							<Image pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" src="/arrow.png" w="64" zIndex={10} m="0 !important" />
						</HStack>
					</VStack>

					<VStack w="full" pt="4">
						<Heading fontSize="sm">All changes</Heading>

						<VStack w="full" spacing={6}>
							{changes[Number(site)].map((change, i) => (
								<VStack key={i} w="full">
									<Heading fontSize="sm" color="red.600">
										{change}
									</Heading>
									<HStack pos="relative" spacing={6}>
										<Image src={`/image-${Number(site) + 1}-original-${i + 1}.jpg`} border="4px solid" borderColor="success.600" />

										<Image src={`/image-${Number(site) + 1}-change-${i + 1}.jpg`} border="4px solid" borderColor="error.600" />
										<Image pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" src="/arrow.png" w="24" zIndex={10} m="0 !important" />
									</HStack>
								</VStack>
							))}
						</VStack>
					</VStack>
				</VStack>
			) : (
				<VStack w="full" align="center">
					<Heading fontSize="sm" color="grayscale.label">
						No change detected between
					</Heading>

					<HStack spacing={6}>
						<Heading fontSize="md" color="primary.500">
							{format(new Date(dates[Number(image) + 1]), "MMM dd, yyyy")}
						</Heading>

						<Heading fontSize="md" color="primary.500">
							{format(new Date(dates[Number(image)]), "MMM dd, yyyy")}
						</Heading>
					</HStack>

					<HStack w="full" pos="relative" spacing={6}>
						<AspectRatio w="full" maxW="3xl" ratio={16 / 9}>
							<Image src={`/image-${Number(site) + 1}.jpg`} border="6px solid" borderColor="success.600" />
						</AspectRatio>

						<AspectRatio w="full" maxW="3xl" ratio={16 / 9}>
							<Image src={`/image-${Number(site) + 1}.jpg`} border="6px solid" borderColor="success.600" />
						</AspectRatio>

						<Image pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" src="/arrow.png" w="64" zIndex={10} m="0 !important" />
					</HStack>
				</VStack>
			)}
		</VStack>
	)
}

export default ChangesPage
